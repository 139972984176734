<template>
    <div>
        <div class="main-content">
            <div class="content">
                <div class="tab">
                    <el-button type="primary" @click="createBtn">添加换购商品</el-button>
                </div>
                <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px" height="1%">
                    <el-table-column prop="goods_name" label="商品主图" align="center">
                        <template slot-scope="scope">
                            <img class="goods-img" :src="scope.row.img_url" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
                    <el-table-column prop="exchange_points" label="换购积分" align="center"></el-table-column>
                    <el-table-column prop="exchange_total_num" label="换购次数" align="center"></el-table-column>
                    <el-table-column prop="browse_num" label="浏览次数" align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" @click="editBtn(scope.row)">编辑</el-link>
                            <el-link type="danger" :underline="false" @click="delBtn(scope.row)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pages-center"
                               :current-page="listPages.currentPageNum"
                               :page-size="listPages.eachPageNum"
                               layout="prev, pager, next, jumper"
                               :total="listPages.total"
                               @current-change="pageCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {exchangeGoodsListAdmin, exchange_goods_delExchangeGoods} from '@/config/apis'

    export default {
        data() {
            return {
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    paging: '1',
                    pageSize: this.listPages.eachPageNum,
                    page: this.listPages.currentPageNum,
                }
                exchangeGoodsListAdmin(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.infoList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            delBtn(row) {
                this.$confirm('删除后将无法恢复，确定要删除？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    exchange_goods_delExchangeGoods({exchange_goods_id: row.exchange_goods_id}).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            editBtn(row) {
                this.$router.push({
                    path: '/school/pointsManage/rewardPoints/detail',
                    query: {
                        id: row.exchange_goods_id
                    }
                })
            },
            createBtn() {
                this.$router.push({
                    path: '/school/pointsManage/rewardPoints/detail'
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .goods-img {
        max-width: 200px;
        height: 100px;
    }

    ::v-deep .custom-dialog {
        min-width: 500px;
    }
</style>